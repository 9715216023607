import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";
import WebformElements from "../webform-elements";

const Flexbox = ({ item, token, compositeIndex, compositeParent }) => {
  const { values } = useFormikContext();

  const { invisible, visible } = states(item.states, values);

  return (
    <div
      className={classNames({
        "form-group webform-flexbox": true,
        hidden: invisible || !visible,
      })}
    >
      <div
        style={
          item.flex
            ? { flex: item.flex, alignItems: item.alignItems }
            : { alignItems: item.alignItems }
        }
        className="flexbox-wrap"
      >
        {console.log(item)}
        <WebformElements
          items={item.elements}
          token={token}
          compositeParent={compositeParent}
          compositeIndex={compositeIndex}
        />
      </div>

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />

      {!!item.description && (
        <small className="form-description text-muted form-text">
          {item.description}
        </small>
      )}
    </div>
  );
};

Flexbox.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    alignItems: PropTypes.oneOf(["flex-start", "center", "flex-end"]),
    description: PropTypes.string,
    states: PropTypes.array,
    flex: PropTypes.number,
    elements: PropTypes.array,
  }),
  token: PropTypes.string.isRequired,
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default Flexbox;
