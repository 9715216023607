import React from "react";
import FormPageQuery from "./form.graphql";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import ParagraphFormular from "../general-components/content-base/paragraphs/webform/paragraph-formular";
import { Helmet } from "react-helmet";

const FormPage = () => {
  const { pathname } = useLocation();
  const { data, loading, error } = useQuery(FormPageQuery, {
    variables: {
      path: pathname,
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const formId = data?.route?.entity?.entityId;

  console.log(data);
  
  if (!formId) {
    return <div>Formular existiert nicht.</div>;
  }

  return (
    <article className="node node-form py-5">
      <Helmet>
        <title>{`${data.route.entity.entityLabel} | Dr. Malek Logistiksoftware`}</title>
      </Helmet>
      <div className="container pt-5">
        <h1>{data.route.entity.entityLabel}</h1>
      </div>
      <div>
        <ParagraphFormular
          content={{
            fieldFormularAuswahl: {
              targetId: formId,
            },
          }}
        />
      </div>
    </article>
  );
};

export default FormPage;
