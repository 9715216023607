import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import CompositeField from "./elements/composite";
import TextField from "./elements/text-field";
import TextAreaField from "./elements/textarea-field";
import EmailField from "./elements/email-field";
import NumberField from "./elements/number-field";
import DateField from "./elements/date-field";
import SelectField from "./elements/select-field";
import RadioButtonField from "./elements/radio-button-field";
import CheckboxesField from "./elements/checkboxes-field";
import HiddenField from "./elements/hidden-field";
import MarkupField from "./elements/markup-field";
import FileField from "./elements/file-field";
import Container from "./elements/container";
import Details from "./elements/details";
import Fieldset from "./elements/fieldset";
import Flexbox from "./elements/flex";
import CompositeAddressField from "./elements/composite-address";
import CheckboxField from "./elements/checkbox-field";
import UrlField from "./elements/url-field";
import { webformElementsCustomExcluded } from "./paragraph-formular";
import CaptchaField from "./elements/captcha-field";

// @see https://stackoverflow.com/a/22648871
export const getValueFromStringObjPath = (obj, path) => {
  const val = path.split(".").reduce(function (p, c) {
    return (Object.prototype.hasOwnProperty.call(p, c) && p[c]) || p;
  }, obj);

  return val;
};

const WebformElements = ({
  items,
  token,
  compositeIndex,
  compositeParent,
  generatedInitialValues,
}) => {
  const { isSubmitting } = useFormikContext();

  return (
    <>
      {!!items?.length &&
        items.map((item, index) => {
          const newItem = JSON.parse(JSON.stringify(item));

          if (webformElementsCustomExcluded.includes(newItem.id)) {
            return;
          }

          switch (item.type) {
            case "webform_flexbox":
              return (
                <Flexbox
                  token={token}
                  item={newItem}
                  key={index}
                  compositeIndex={compositeIndex}
                  compositeParent={compositeParent}
                />
              );

            case "fieldset":
              return (
                <Fieldset
                  token={token}
                  item={newItem}
                  key={index}
                  compositeIndex={compositeIndex}
                  compositeParent={compositeParent}
                />
              );

            case "details":
              return <Details token={token} item={newItem} key={index} />;

            case "container":
              return <Container token={token} item={newItem} key={index} />;

            case "webform_address_composite":
              return (
                <CompositeAddressField
                  item={item}
                  key={index}
                  token={token}
                  generatedInitialValues={generatedInitialValues}
                  compositeIndex={compositeIndex}
                  compositeParent={compositeParent}
                />
              );

            case "webform_custom_composite":
              return (
                <CompositeField
                  item={item}
                  key={index}
                  token={token}
                  generatedInitialValues={generatedInitialValues}
                />
              );

            case "textfield":
              return (
                <TextField
                  key={index}
                  item={newItem}
                  compositeIndex={compositeIndex}
                  compositeParent={compositeParent}
                />
              );

            case "url":
              return (
                <UrlField
                  key={index}
                  item={newItem}
                  compositeIndex={compositeIndex}
                  compositeParent={compositeParent}
                />
              );

            case "textarea":
              return (
                <TextAreaField
                  key={index}
                  item={newItem}
                  compositeIndex={compositeIndex}
                  compositeParent={compositeParent}
                />
              );

            case "email":
              return (
                <EmailField
                  key={index}
                  item={newItem}
                  compositeIndex={compositeIndex}
                  compositeParent={compositeParent}
                />
              );

            case "number":
              return (
                <NumberField
                  key={index}
                  item={newItem}
                  compositeIndex={compositeIndex}
                  compositeParent={compositeParent}
                />
              );

            case "date":
              return (
                <DateField
                  key={index}
                  item={newItem}
                  compositeIndex={compositeIndex}
                  compositeParent={compositeParent}
                />
              );

            case "webform_entity_select":
            case "webform_term_select":
            case "select":
              return <SelectField key={index} item={newItem} />;

            case "radios":
              return <RadioButtonField key={index} item={newItem} />;

            case "checkbox":
              return (
                <CheckboxField
                  key={index}
                  item={newItem}
                  compositeIndex={compositeIndex}
                  compositeParent={compositeParent}
                />
              );

            case "checkboxes":
              return <CheckboxesField key={index} item={newItem} />;

            case "hidden":
              return <HiddenField key={index} item={newItem} />;

            case "webform_markup":
              return (
                <div className="row">
                  <div className="col-8 text">
                    <MarkupField key={index} item={newItem} />
                  </div>
                </div>
              );

            case "managed_file":
              return (
                <FileField
                  language="de"
                  key={index}
                  item={newItem}
                  token={token}
                />
              );

            case "webform_actions":
              return (
                <>
                  <CaptchaField />
                  <button
                    aria-label="Submit Form"
                    tabIndex={0}
                    type="submit"
                    key={index}
                    disabled={isSubmitting}
                    className="btn btn-secondary"
                    id={newItem.id}
                  >
                    {newItem.submitLabel}
                  </button>
                </>
              );

            default:
              return false;
          }
        })}
    </>
  );
};

WebformElements.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      states: PropTypes.object,
    })
  ),
  token: PropTypes.string.isRequired,
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
  generatedInitialValues: PropTypes.object,
};

export default WebformElements;
