import React from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";
import FieldTime from "../../date-time/field-time";
import moment from "moment";
import { Link } from "react-router-dom";

const TeaserBlog = ({ item }) => {
  const { adminApp } = useSelector((reduxStore) => ({
    adminApp: reduxStore.appStore.adminApp,
  }));

  return (
    <article className="node node-teaser teaser-blog">
      <Link to={item.path.alias}>
        <p className="small meta">
          <div className="tags">
            {!!item.fieldBlogTags?.length &&
              item.fieldBlogTags.map((tag) => (
                <span key={tag.entity.tid} className="tag">
                  {tag.entity.name}
                </span>
              ))}
          </div>
          <div>{moment(item.entityCreated).format("dddd, DD. MMMM YYYY")}</div>
        </p>
        <div className="content">
          <h2>{item.title}</h2>
          <div className="d-flex">
            {item.fieldTeaserbild?.entity && (
              <Image
                data={item.fieldTeaserbild.entity.fieldMediaImage}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                }}
              />
            )}
            <p>{item.fieldSummary}</p>
          </div>
        </div>
      </Link>
    </article>
  );
};

export const teaserNewsPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  publishedAt: PropTypes.shape({
    value: PropTypes.number,
    publishedAtOrNow: PropTypes.number,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTeasertext: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldTeaserbild: PropTypes.shape({
    entity: PropTypes.shape({
      entityBundle: PropTypes.string,
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldDate: PropTypes.object,
});

TeaserBlog.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserNewsPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default withRouter(TeaserBlog);
