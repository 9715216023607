import React from "react";
import ConfirmationPageQuery from "./confirmation.graphql";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

const ConfirmationPage = () => {
  const { formId } = useParams();
  const { data, loading, error } = useQuery(ConfirmationPageQuery, {
    variables: {
      webform_id: formId,
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const content = data?.webformById?.settings;

  if (!formId) {
    return <div>Formular existiert nicht.</div>;
  }

  return (
    <article className="node node-form py-5">
      <div className="container pt-5">
        <div className="row">
          <div className="col-12 col-md-8">
            <h1>{content.confirmationTitle}</h1>
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: content.confirmationMessage }}
            />
          </div>
        </div>
      </div>
    </article>
  );
};

export default ConfirmationPage;
