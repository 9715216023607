import React, { useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "../../../image/image";

const ParagraphBlogImage = ({ content }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const sectionClassNames = classNames({
    "paragraph paragraph-image": true,
  });

  const colClassNames = classNames({
    "col-12 col-md-8 col-lg-4": content.fieldSize === "s",
    "col-12 col-md-8": content.fieldSize === "m",
    "col-12": content.fieldSize === "l",
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className={colClassNames}>
            {content.fieldImage && (
              <div onClick={() => setLightboxOpen(true)} style={{ cursor: "pointer" }}>
                <Image data={content.fieldImage.entity.fieldMediaImage} />
              </div>
            )}
            {lightboxOpen &&
              ReactDOM.createPortal(
                <div
                  className="lightbox"
                  onClick={() => setLightboxOpen(false)}
                >
                  <div className="lightbox-inner">
                    <Image data={content.fieldImage.entity.fieldMediaImage} />
                  </div>
                </div>,
                document.getElementById("lightbox-root")
              )}
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphBlogImage.propTypes = {
  content: PropTypes.shape({
    fieldSize: PropTypes.string,
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
  }),
};

export default ParagraphBlogImage;
