import React, { Component } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";

// Router
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// i18n
import { IntlProvider } from "react-intl";
import messages_de from "./i18n/translations/de.json";
import messages_en from "./i18n/translations/en.json";

// Page Components
import Landingpage from "./landingpage/landingpage";
import Event from "./event/event";
import Person from "./person/person";
import configQuery from "config-query.graphql";
import nodeQuery from "node-query.graphql";
import PageFooter from "./footer/page-footer";
import menuQuery from "./general-components/menu-query.graphql";
import SimpleHeader from "./header/simple-header";
import ErrorBoundary from "./error-boundary";
import NodeRevision from "./backend/NodeRevision";
import Blog from "./blog/blog";
import PageLogin from "./intern/pages/page-login";
import PageForgotPassword from "./intern/pages/page-forgot-password";
import PageResetPassword from "./intern/pages/page-reset-password";

import { authenticationLoginAction } from "./intern/redux-authentication";
import { tokenExpired } from "./intern/util";
import PageRegister from "./intern/pages/page-register";
import PageVerifyRegistration from "./intern/pages/page-verify-registration";
import PageVerifyRegistrationSuccess from "./intern/pages/page-verify-registration-success";
import ToastContainer from "./general-components/ui/toast/components/toast-container";
import InternLayout from "./intern/pages/layout";
import PageWaitForActivation from "./intern/pages/page-wait-for-activation";
import PageSetPassword from "./intern/pages/page-set-password";
import PageUserReset from "./intern/pages/page-user-reset";
import FormPage from "./form/form";
import ConfirmationPage from "./form/confirmation";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  auth: reduxStore.auth,
  i18n: reduxStore.i18n,
});

const messages = {
  de: messages_de,
  en: messages_en,
};

/**
 * @todo 404.
 * @todo Fetch Page title and push to store. Use for <title> tag.
 */
class App extends Component {
  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  componentDidMount() {
    // Clear login status if jwt is expired.
    if (
      this.props.auth?.userLoginData.access_token &&
      tokenExpired(
        this.props.auth?.userLoginData.access_token,
        this.props.auth?.userLoginData.jwt.exp
      )
    ) {
      this.props.dispatch(authenticationLoginAction({}));
    }

    document.body.className += `${this.generateMainClasses(
      this.props.location.pathname
    )}`;
  }

  componentDidUpdate(prevProps) {
    // Clear login status if jwt is expired.
    if (
      this.props.auth?.userLoginData.access_token &&
      tokenExpired(
        this.props.auth?.userLoginData.access_token,
        this.props.auth?.userLoginData.jwt.exp
      )
    ) {
      this.props.dispatch(authenticationLoginAction({}));
    }

    // Scroll to top on route change
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.hash
    ) {
      window.scrollTo(0, 0);
      document.body.className = `${this.generateMainClasses(
        this.props.location.pathname
      )}`;
    }
  }

  render() {
    return (
      <IntlProvider
        locale={this.props.i18n.currentLanguage}
        defaultLocale="de"
        messages={messages[this.props.i18n.currentLanguage]}
      >
        <div className="main-content">
          <ErrorBoundary>
            <SimpleHeader
              products={this.props.products.nodeQuery?.entities}
              headerMetaConfig={
                this.props.headerMetaConfig.configPagesQuery?.entities[0]
              }
            />
          </ErrorBoundary>

          <main className="main-page-content">
            <ErrorBoundary>
              <Switch
                location={this.props.location}
                key={this.props.location.pathname}
              >
                {/* Internal Pages */}
                <Route exact path="/intern/login" component={PageLogin} />
                <Route
                  exact
                  path="/intern/forgot-password"
                  component={PageForgotPassword}
                />
                <Route
                  exact
                  path="/intern/reset-password"
                  component={PageResetPassword}
                />
                <Route exact path="/intern/register" component={PageRegister} />
                <Route
                  exact
                  path="/intern/verify-registration"
                  component={PageVerifyRegistration}
                />
                <Route
                  exact
                  path="/intern/verify-registration-success"
                  component={PageVerifyRegistrationSuccess}
                />
                <Route
                  exact
                  path="/intern/wait-for-activation"
                  component={PageWaitForActivation}
                />
                <Route
                  exact
                  path="/intern/set-password"
                  component={PageSetPassword}
                />
                <Route
                  exact
                  path="/intern/user-reset/:uid/:timestamp/:hash"
                  component={PageUserReset}
                />
                <Route path="/intern" component={InternLayout} />
                {/* <Route exact path="/intern/:alias" component={PageIntern} /> */}
                {/* Public Pages */}
                <Route
                  exact
                  path="/:language?/projekt/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/:language?/news/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/:language?/veranstaltung/:alias"
                  component={Event}
                />
                <Route
                  exact
                  path="/:language?/person/:alias"
                  component={Person}
                />
                <Route
                  exact
                  path="/:language?/loesung/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/:language?/produkt/:alias"
                  component={Landingpage}
                />
                <Route exact path="/:language?/blog/:alias" component={Blog} />
                <Route exact path="/:language?/form/:formId" component={FormPage} />
                <Route exact path="/:language?/form/confirmation/:formId" component={ConfirmationPage} />
                <Route exact path="/:language?/webform/:formId" component={FormPage} />
                <Route
                  exact
                  path="/:language?/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/node/:nodeId/revisions/:revisionId/view"
                  component={NodeRevision}
                />
                <Route exact path="/" component={Landingpage} />
              </Switch>
            </ErrorBoundary>
            <ToastContainer />
          </main>

          <footer id="page-footer">
            <ErrorBoundary>
              <PageFooter
                mainMenu={this.props.mainMenu.menuByName}
                footerConfig={
                  this.props.footerConfig.configPagesQuery?.entities[0]
                }
                footerMenu={this.props.footerMenu.menuByName}
              />
            </ErrorBoundary>
          </footer>
        </div>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  footerConfig: PropTypes.object.isRequired,
  footerMenu: PropTypes.object.isRequired,
  headerMetaConfig: PropTypes.object.isRequired,
  mainMenu: PropTypes.object.isRequired,
  auth: PropTypes.object,
  i18n: PropTypes.object,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  compose(
    graphql(configQuery, {
      options: {
        variables: { name: "footer_social_media" },
      },
      name: "footerConfig",
    }),
    graphql(configQuery, {
      options: {
        variables: { name: "header_meta" },
      },
      name: "headerMetaConfig",
    }),
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: "footer",
          language: props.i18n.currentLanguage
            ? props.i18n.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "footerMenu",
    }),
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: "main",
          language: props.i18n.currentLanguage
            ? props.i18n.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "mainMenu",
    }),
    graphql(nodeQuery, {
      options: {
        variables: {
          type: "product",
        },
      },
      name: "products",
    }),
    graphql(nodeQuery, {
      options: {
        variables: {
          type: "solution",
        },
      },
      name: "solutions",
    })
  )(withRouter(App))
);
